<template>
  <div class="col-lg-4 padCustom">
    <div class="sectionThreeBoxWrap">
      <div class="insideItemBoxWrap">
        <draggable
          v-model="dashboardSort.section_3"
          class="section-three row"
          group="table"
          :class="isDisable ? 'section-three-in-active' : ''"
          :style="{ flex: isSectionOne_Two && 1 }"
          :disabled="isDisable"
          @change="updateOrder"
        >
          <div
            v-for="(row, i) in dashboardSort?.section_3"
            :key="i"
            :loading="loading"
            :class="getClassName()"
          >
            <!-- col-lg-6 -->
            <div
              :class="[
                'smallSectionTwoBox',
                (row.keys[0] === 'average_soc' || row.keys[0] === 'savings') ? 'sortingDiv' : '',
                getStyle()
              ]"
            >
              <div class="valueBox">
                <p>
                  <span> {{ $t(`units.${row.keys[0]}`) | currencyFormat(authUser) }}</span>{{
                    dashboadData[row?.keys[0]] }}
                </p>
              </div>
              <div
                v-if="row.keys[0] == 'savings' || row.keys[0] == 'average_soc'"
                :class="['iconBox', getIconColor(row.keys[0])]"
              >
                <Icons :name="getIconName(row.keys[0])" />
              </div>
              <div class="contentBox">
                {{ row.card_name }}
              </div>
              <div
                v-if="row.keys[0] == 'electricity_cost'"
                class="barChart lineChartBasic"
              >
                <ApexLineChart
                  :width="'100%'"
                  :height="setHeigth()"
                />
              </div>
              <div
                v-else-if="row.keys[0] == 'fuel_cost'"
                class="barChart"
              >
                <ApexLineWithLabelsChart
                  :width="'100%'"
                  :height="setHeigth()"
                />
              </div>
              <div
                v-else-if="row.keys[0] == 'average_soc'"
                class="barChart"
              >
                <ApexAreaChartBasic
                  :width="'100%'"
                  :height="setHeigth()"
                />
              </div>
              <div
                v-else-if="row.keys[0] == 'savings'"
                class="barChart"
              >
                <ApexBarChart
                  :width="'100%'"
                  :height="setHeigth()"
                />
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
  components: {
    draggable,
    Icons: () => import("@/components/base/icons.vue"),
    ApexBarChart: () => import("@/components/sections/ApexBarChart.vue"),
    ApexAreaChartBasic: () => import("@/components/sections/ApexAreaChartBasic.vue"),
    ApexLineWithLabelsChart: () => import("@/components/sections/ApexLineWithLabelsChart.vue"),
    ApexLineChart: () => import("@/components/sections/ApexLineChart.vue"),

  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    vehicleTech: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      iconName: ""
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "dashboard/getIsLoading",
      dashboadData: "dashboard/getDashboadData",
      dashboardSort: "dashboard/getDashboadSortData",
      //
      selectedCards: "dashboard/getSelectedCards",
      //
      vehicleList: "vehicles/getVehiclesList",
    }),
    isSectionOne_Two() {
      return !this.dashboardSort?.section_1 && !this.dashboardSort?.section_2;
    },
    isDisable() {
      return this.selectedCards.length == 0 ? true : false;
    }
  },
  watch: {
    vehicleList: {
      handler: function () {
        this.showMap = false;
        setTimeout(() => {
          this.showMap = true;
        }, 100);
      },
    },
  },
  methods: {
    getIconColor(key) {
      if (key == "average_soc") {
        return "blueBg"
      } else {
        return "greenBg"
      }
    },
    getIconName(key) {
      if (key == "average_soc") {
        return "battery-efficiency"
      } else {
        return "savings"
      }
    },
    updateOrder() {
      const params = {
        section: "section_3",
        sort: true,
        item: this.dashboardSort.section_3,
        vehicle_tech: this.vehicleTech,
        // from: val.moved.oldIndex,
        // to: val.moved.newIndex,
      };

      this.$store.dispatch("dashboard/updateCard", params);
    },

    getClassName() {
      if ((this.dashboardSort.section_3) && this.dashboardSort.section_3.length <= 2) {
        return 'col-lg-12';
      }
      return 'col-lg-6';
    },
    getStyle() {
      return ((this.dashboardSort.section_3) && this.dashboardSort.section_3.length == 1) ? "minHeight" : '';
    },
    setHeigth() {
      if (this.dashboardSort.section_3) {
        if (this.dashboardSort.section_3.length == 1) {
          return "350px"
        } else if (this.dashboardSort.section_3.length == 2) {
          return "140px"
        } else if (this.dashboardSort.section_3.length <= 3) {
          return "100px"
        }else{
          return "100px"
        }
      }
      return "100px"
    }
  },

};
</script>
<style lang="sass" scoped>

.section-two-in-active
  .v-card
    cursor: auto !important

.section-three
  width: 35%
  .v-card
    cursor: all-scroll
    background: transparent !important

@media only screen and (max-width: 960px)
  .section-three
    width: 100%
</style>
